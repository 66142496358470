import { Injectable } from '@angular/core';
import { User, UserManager } from 'oidc-client-ts';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userManager: UserManager;
  empId: string = '';
  isSuperUser: boolean = false;

  constructor() {
    const settings = {
      authority: environment.stsAuthority,
      client_id: environment.clientId,
      client_secret: environment.clientSecret,
      redirect_uri: `${environment.clientRoot}identity/signInCallback`,
      silent_redirect_uri: `${environment.clientRoot}silent-callback.html`,
      post_logout_redirect_uri: `${environment.clientRoot}`,
      response_type: 'code',
      scope: environment.clientScope,
    };
    this.userManager = new UserManager(settings);
  }

  getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  getAccessToken(): Promise<string | null> {
    return this.userManager.getUser().then(user => {
      if (user && !user.expired) {
        return user.access_token;
      } else {
        return null;
      }
    });
  }

  login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  renewToken(): Promise<User | null> {
    return this.userManager.signinSilent();
  }

  logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }

  setEmpId(user: any) {
    this.empId = user?.profile['CnsIdEmployee'] as string;
    this.isSuperUser = user?.profile.is_super_user;
  }
}
